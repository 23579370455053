import React from 'react';
import MuiTabPanel from "@mui/lab/TabPanel";
import CircularProgress from '@mui/material/CircularProgress';
import Grid from "@mui/material/Grid";

export default function TabPanel(props) {
    if (props.loading) {
        return <Grid container justifyContent={'center'} sx={{p: 20}}>
            <Grid item>
                <CircularProgress />
            </Grid>
        </Grid>
    }
    return <MuiTabPanel {...props} />
}