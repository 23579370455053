import s from "./Body.module.css";
import React, { useState, useEffect } from "react";
import Header from "./Header/Header";
import Main from "./Main/Main";
import Auth from "./Auth/Auth";
import {
  getUserId,
  setApiTokenToCookie,
  setCookie,
  sendRequest,
  empty,
} from "../helpers/helpers";
// import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

function Body() {
  const [isAuth, setIsAuth] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const userId = getUserId();

  window.authError = authError;

  function authError() {
    setIsAuth(false);
    setApiTokenToCookie("");
    setCookie("userId", "");
  }

  useEffect(() => {
    // console.log('authCheck')
    setIsLoading(true);
    async function authCheck() {
      const response = await sendRequest("authCheck");
      if (response.data && response.data.result) {
        // console.log('response.data - ', response.data)
        return response.data.result.status === "success";
      }

      return false;
    }

    authCheck().then((r) => {
      setIsAuth(r);
    });

    setIsLoading(false);
  }, []);

  function authState(status) {
    setIsAuth(status);
  }

  if (isLoading === true) {
    // return <Loading fullscreen={true} className={s.loading_auth}/>;
    return <div>Загрузка</div>;
  } else if (isAuth === false || empty(userId)) {
    return <Auth func={authState} />;
  }

  return (
    <Grid container>
      <Grid item className={s.header}>
        <Header />
      </Grid>
      <Grid item className={s.content}>
        <Main />
      </Grid>
    </Grid>
  );
}

export default Body;
