import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import { sendRequest } from "../../../helpers/helpers";
import Attachments from "../../../ui/Attachments";
import TabPanel from "../../../ui/TabPanel";

const FormattedText = ({text}) => (
    <Box component="span" sx={{whiteSpace: "pre-line"}}>
        {text}
    </Box>
);

function DataField({label, value}) {
    return (
        <Grid item xs={12}>
            <Grid container spacing={2}>
                <Grid item xs={6} sx={{textAlign: "right"}}>
                    <b>{label}:</b>
                </Grid>
                <Grid item xs={6}>
                    {value}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default function RequestCard({id, close}) {
    const [tab, setTab] = useState("1");
    const [data, setData] = useState({});
    // eslint-disable-next-line no-unused-vars
    const [attach, setAttach] = useState();
    // eslint-disable-next-line no-unused-vars
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);

        if (id) {
            setTab('1');
            sendRequest("getById", {request_id: id})
                .then(({data}) => {
                    if (data?.result?.status === 'success') {
                        setData(data?.result?.data ?? {});
                    }
                    setLoading(false);
                })
        }
    }, [id]);

    return !id ? null : (
        <Box sx={{width: "100%", position: "sticky", top: 80}}>
            <Card sx={{height: "100%"}} variant="outlined">
                <TabContext value={tab}>
                    <Box sx={{borderBottom: 1, borderColor: "divider"}}>
                        <Grid
                            container
                            spacing={2}
                            alignItems={"center"}
                            sx={{width: "100%"}}
                            justifyContent={"space-around"}
                        >
                            <Grid item xs={10}>
                                <TabList
                                    onChange={(e, v) => {
                                        setTab(v);
                                    }}
                                >
                                    <Tab label="Основа" value="1"/>
                                    <Tab label="История" value="2"/>
                                    <Tab label="Комментарии" value="3"/>
                                    <Tab label="Файлы" value="4"/>
                                </TabList>
                            </Grid>
                            <Grid item xs={1}>
                                <Button onClick={close} sx={{margin: 0, padding: 0}}>
                                    <CloseIcon pt={2}/>
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                    <TabPanel value="1" loading={loading}>
                        <Grid container spacing={2}>
                            <DataField label="Адрес МКД" value={data.address}/>
                            <DataField label="Номер помещения" value={data.room_num}/>
                            <DataField label="Заявка" value={data.id}/>
                            <DataField
                                label="Дата и время регистрации"
                                value={data.date_start}
                            />
                            <DataField label="Управляющая компания" value={data.uk}/>
                            <DataField
                                label="Исполнитель (организация)"
                                value={data.contractor}
                            />
                            <DataField label="Тема обращения" value={data.theme}/>
                            <DataField label="Статус" value={data.status}/>
                            <DataField
                                label="Вид заявки (Источник возникновения)"
                                value={data.type}
                            />
                        </Grid>
                    </TabPanel>
                    <TabPanel value="2">
                        <FormattedText text={data.history}/>
                    </TabPanel>
                    <TabPanel value="3">
                        <FormattedText text={data.comment}/>
                    </TabPanel>
                    <TabPanel value="4">
                        <Attachments id={id} loading={loading} setLoading={setLoading} />
                    </TabPanel>
                </TabContext>
            </Card>
        </Box>
    );
}
