import React, { useCallback, useEffect, useRef, useState } from 'react';
import Box from "@mui/material/Box";
import s from './DynamicList.module.css';
import { sendRequest } from "../../../../helpers/helpers";
import { DataGrid, ruRU } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "../../../../ui/CustomNoRowsOverlay";
import useInterval from "../../../../helpers/useInterval";
// import AttachFileIcon from '@mui/icons-material/AttachFile';
import { rowStyles, getRowClassName } from "../../../../helpers/helpers"
import AddressCell from "../../../../ui/AddressCell";
import ContractorCell from "../../../../ui/ContractorCell";
import DefaultCell from "../../../../ui/DefaultCell";
import DataGridFooter from "../../../../ui/MoreDataGridFooter";
import debounce from "lodash/debounce";

export default function DynamicList({ filter, selectCard, setFilter }) {
    const classes = rowStyles();
    const initPageSize = 25;

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [lastRequestDate, setLastRequestDate] = useState(false);
    const [lastDateTimeUTC, setLastDateTimeUTC] = useState(false);
    const mounted = useRef();
    const [showFooter, setShowFooter] = useState(true);
    const [pageSize, setPageSize] = useState(initPageSize);
    const [visibilityWindow, setVisibilityWindow] = useState({ hidden: '', visibilityChange: '' });
    const [delay, setDelay] = useState(5000);

    let columns = [
        {
            headerName: 'Дата приема заявки',
            field: 'date_start',
            flex: 1,
            // width: 180,
            headerClassName: s.th,
            align: 'center',
            headerAlign: "center",
            sortable: false,
            renderCell: function renderDefaultCell(cell) {
                return <DefaultCell cell={cell} selectCard={selectCard} />
            },
        },
        {
            headerName: 'Адрес МКД',
            field: 'address',
            flex: 2,
            // width: 400,
            headerClassName: s.th,
            headerAlign: "center",
            sortable: false,
            renderCell: function renderAddress(cell) {
                return <AddressCell cell={cell} setFilter={setFilter} filter={filter} />
            }
        },
        {
            headerName: 'Номер помещения',
            field: 'room_num',
            flex: 0.5,
            // width: 180,
            headerClassName: s.th,
            align: 'center',
            headerAlign: "center",
            sortable: false,
            renderCell: function renderDefaultCell(cell) {
                return <DefaultCell cell={cell} selectCard={selectCard} />
            },
        },
        {
            headerName: 'Тема обращения',
            field: 'theme',
            flex: 2,
            // width: 350,
            headerClassName: s.th,
            headerAlign: "center",
            sortable: false,
            renderCell: function renderDefaultCell(cell) {
                return <DefaultCell cell={cell} selectCard={selectCard} />
            },
        },
        {
            headerName: 'Статус',
            field: 'status',
            headerClassName: s.th,
            align: "center",
            flex: 0.5,
            // width: 120,
            headerAlign: "center",
            sortable: false,
            renderCell: function renderDefaultCell(cell) {
                return <DefaultCell cell={cell} selectCard={selectCard} />
            },
        },
        {
            headerName: 'Исполнитель',
            field: 'contractor',
            headerClassName: s.th,
            align: "center",
            flex: 2,
            // width: 250,
            headerAlign: "center",
            sortable: false,
            // eslint-disable-next-line react/display-name
            renderCell: (cell) => {
                return <ContractorCell cell={cell} setFilter={setFilter} filter={filter} />
            }
        },
        // {
        //     headerName: 'Файл',
        //     field: 'attach',
        //     headerClassName: s.th,
        //     align: "center",
        //     flex: 0.2,
        //     headerAlign: "center",
        //     sortable: false,
        //     renderCell: (cell) => {
        //         return cell.row.attach ? <AttachFileIcon /> : null
        //     }
        // }
    ];



    useEffect(() => {
        updateList(filter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter.from, filter.to, filter.show_finished, filter.status, filter.theme, filter.show_time]);

    //Если мы ушли (свернули браузер, перешли на другую вкладку браузера), а потом вернулись - изменяем интервал отправки до 120 сек
    //Сделано, чтобы после долгого перерыва, не прилетало одновременно много новых заявок.
    useEffect(() => {
        if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
            setVisibilityWindow({ hidden: "hidden", visibilityChange: "visibilitychange" })
        } else if (typeof document.msHidden !== "undefined") {
            setVisibilityWindow({ hidden: "msHidden", visibilityChange: "msvisibilitychange" })
        } else if (typeof document.webkitHidden !== "undefined") {
            setVisibilityWindow({ hidden: "webkitHidden", visibilityChange: "webkitvisibilitychange" })
        }

        function handleVisibilityChange() {
            if (document[visibilityWindow.hidden]) {
                // console.log('exit from page')
                setDelay(120000)
            } else {
                // console.log('return to page')
                setDelay(5000)
            }
        }

        document.addEventListener(visibilityWindow.visibilityChange, handleVisibilityChange, false);
        return () => {
            // unsubscribe event
            document.removeEventListener(visibilityWindow.visibilityChange, handleVisibilityChange);
        };
    }, [document.hidden]);



    function updateList(filter) {
        setLoading(true);
        setData([]);
        setLastRequestDate(false);
        setLastDateTimeUTC(false);

        const requestData = {
            offset: 0,
            pageSize,
            ...filter
        };

        Object.keys(requestData).forEach((i) => {
            if (requestData[i] === null) {
                requestData[i] = '';
            }
        });

        async function getList() {
            const response = await sendRequest('getMoreList', requestData);
            if (response.data && response.data.result && response.data.result.status === 'success') {
                return response.data.result ?? [];
            }
            return [];
        }

        getList().then(result => {
            if (result.data && result.data.length > 0) {
                setData(result.data);
                if (result.data.length < initPageSize) {
                    setShowFooter(false);
                } else {
                    setShowFooter(true)
                }
                setLastRequestDate(result.data[0].date_start);
                setLastDateTimeUTC(result.data[0].datetime_UTC);
            }
            setLoading(false);
        });
    }

    const debouncedUpdateList = useCallback(debounce(updateList, 3000), []);
    useEffect(() => {
        //emulating componentDidUpdate
        if (!mounted.current) {
            mounted.current = true;
        } else {
            debouncedUpdateList(filter);
        }
    }, [filter.address, filter.contractor]);

    // setDelay(5000)
    useInterval(checkNewRequests, delay);
    useInterval(unsetNewRow, delay + 500);

    async function showMore() {
        setLoading(true);

        let newPageSize = pageSize + initPageSize
        setPageSize(newPageSize)

        const requestData = {
            offset: data.length,
            pageSize: initPageSize,
            ...filter
        };

        Object.keys(requestData).forEach((i) => {
            if (requestData[i] === null) {
                requestData[i] = '';
            }
        });

        const response = await sendRequest('getMoreList', requestData);
        let result = [];
        if (response.data && response.data.result && response.data.result.status === 'success') {
            result = response.data.result.data ?? [];
        }

        if (result.length > 0) {
            setData([...data, ...result]);
        }
        if (result.length < initPageSize) {
            setShowFooter(false);
        } else {
            setShowFooter(true);
        }

        setLoading(false);
    }

    async function checkNewRequests() {
        //Убрал ' && !document.hidden ', так как нужно чтобы запросы шли при свернутой вкладке/браузере
        if (lastRequestDate && lastDateTimeUTC) {
            const requestData = {
                date: lastRequestDate,
                datetime_UTC: lastDateTimeUTC,
                pageSize,
                ...filter
            };

            Object.keys(requestData).forEach((i) => {
                if (requestData[i] === null) {
                    requestData[i] = '';
                }
            });

            const response = await sendRequest('getNewRequests', requestData);
            let result = [];

            if (response.data && response.data.result && response.data.result.status === 'success') {
                result = response.data.result.data ?? [];
            }

            if (result.length > 0) {
                result.forEach(element => {
                    element.datetime_expiry = new Date();
                    element.animated = false
                });

                let newData = [...result, ...data];
                newData = newData.slice(0, pageSize);

                setData(newData);
                setLastRequestDate(result[0].date_start);
                setLastDateTimeUTC(result[0].datetime_UTC);


                //Через секунду (после завершения анимации) - проставляем признак анимированности, что бы повторно не применялась анимация к элементам
                // setTimeout(() => {
                //     setAnimated(newData);
                // }, 1000);
            }
        }
    }

    // function setAnimated(newData) {
    //     newData.forEach(element => {
    //         element.animated = true
    //     });
    //     setData(newData);
    // }

    // Устанавливаем дату и время что бы понимать когда убираеть выделение со строки
    function unsetNewRow() {
        let data_copy = [...data];

        //Если с момента загрузки новой заявки прошло более 10 сек - снимаем выделение
        data_copy.forEach(element => {
            if (element.datetime_expiry && (new Date() - element.datetime_expiry) > 10000) {
                element.is_new = false
            }
        });

        setData(data_copy);
    }


    function onRowHover(e) {
        if (e.target.parentElement.attributes['data-rowindex']) {
            const rowIndex = e.target.parentElement.attributes['data-rowindex'].value;

            if (rowIndex <= data.length && data[rowIndex] && data[rowIndex].is_new) {
                let newData = [...data];
                if (newData[rowIndex]) {
                    newData[rowIndex].is_new = false;
                }

                setData(newData);
            }
        }
    }

    return (
        <Box className={classes.root}>
            <DataGrid
                autoHeight
                rows={data}
                columns={columns}
                disableSelectionOnClick
                disableColumnMenu
                disableColumnFilter
                getRowClassName={getRowClassName}
                localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                loading={loading}
                components={{
                    NoRowsOverlay: CustomNoRowsOverlay,
                    Footer: DataGridFooter.bind(this, { loading: loading, onClick: showMore, show: showFooter })
                }}
                componentsProps={{
                    row: { onMouseOver: onRowHover }
                }}
                rowsPerPageOptions={[]}
                // pageSize={-1}
            // onRowClick={row => selectCard(row.id)}
            />
        </Box>
    );
}
