import React, {useState} from "react";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";

export default function AddressCell({cell, setFilter, filter}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const setHouseInFilter = () => {
        setFilter({...filter, address: cell.value});
        handleClose();
    };
    const setFlatInFilter = () => {
        setFilter({...filter, address: cell.value + ', ' + cell.row.room_num});
        handleClose();
    };
    const setThemeInFilter = () => {
        setFilter({...filter, theme: cell.row.theme});
        handleClose();
    };
    return (
        <Box sx={{width: '100%', height: '100%',}}>
            <Box sx={{
                width: '100%',
                height: '100%',
                lineHeight: '51px',
                color: '#0235a0',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center'
            }}
                 onClick={handleClick}
            >
                <Box sx={{
                    lineHeight: '16px',
                    borderBottom: '1px solid #0235a0',
                    paddingBottom: '3px',
                }}>
                    {cell.formattedValue}
                </Box>
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={setHouseInFilter}>Показать все заявки с этого МКД</MenuItem>
                <MenuItem onClick={setFlatInFilter}>Показать все заявки с этого помещения</MenuItem>
                <MenuItem onClick={setThemeInFilter}>Показать все заявки по этой теме</MenuItem>
            </Menu>
        </Box>
    );
}