/* eslint-disable */

import React, { useEffect, useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Snackbar } from "@mui/material";

import Grid from "@mui/material/Grid";
import { sendRequest } from "../helpers/helpers";
import { parseDateTime } from "../helpers/convert";
import s from "./Attachments.module.css";

export default function Attachments({id, loading, setLoading}) {
    const [data, setData] = useState([]);
    const [showNotice, setshowNotice] = useState(false);
    const ext = "mp3";

    useEffect(() => {
        setLoading(true)
        if (!!id) {
            sendRequest("getFilesByRequestId", {request_id: id})
                .then(({data}) => {
                    if (data?.result?.status === 'success') {
                        setData(data?.result?.data?.files ?? null);
                    }
                    setLoading(false)
                })
        }
    }, [id]);

    async function onDownload(i) {
        let newData = [...data];
        newData[i].loading = true;
        setData(newData);

        const response = await sendRequest("getFileOnLink", {
            link: data[i].Link,
            type: data[i].Type,
        });
        if (
            response.data &&
            response.data.result &&
            response.data.result.status === "success"
        ) {
            const resp = response.data.result.data;
            const newData = [...data];
            newData[i].loading = false;
            newData[i].file = resp.file;
            if (!resp.file) {
                newData[i].fileExtension = "";
                setshowNotice(true);
            } else {
                newData[i].fileExtension = resp.fileExtension;
            }

            setData(newData);
        }
    }

    const PlayArea = ({i}) => {
        let elem = "";
        if (!data[i].file) {
            elem = (
                <LoadingButton
                    onClick={() => onDownload(i)}
                    loading={data[i].loading}
                    loadingPosition="center"
                    variant="outlined"
                >
                    <DownloadIcon/>
                </LoadingButton>
            );
        } else {
            elem = (
                <audio controls>
                    <source
                        src={`data:audio/${ext};base64,${data[i].file}`}
                        type={`audio/${ext}`}
                    />
                </audio>
            );
        }

        return elem;
    };

    if (loading) {
        return null;
    }

    return !data ? (
        "Нет прикрепленных файлов"
    ) : (
            <>
                <Snackbar
                    open={showNotice}
                    autoHideDuration={5000}
                    onClose={() => setshowNotice(false)}
                    message="Идет получение файла из системы телефонии. Процесс занимает 10-15 минут."
                    anchorOrigin={{vertical: "top", horizontal: "center"}}
                    className={s.snackbar}
                >
                    <Alert severity="info" onClose={() => setshowNotice(false)}>
                        {
                            "Идет получение файла из системы телефонии. Процесс занимает 10-15 минут."
                        }
                    </Alert>
                </Snackbar>
                <Grid container spacing={2}>
                    {data.map((item, i) => {
                        return (
                            <Grid container item key={item.Link}>
                                <Grid container item xs={7}>
                                    <Grid item xs={12}>{`ФИО: ${item.CreatorName}`}</Grid>
                                    <Grid item xs={12}>{`Должность: ${item.CreatorPosition}`}</Grid>
                                    <Grid item xs={12}>{`Компания: ${item.CreatorCompany}`}</Grid>
                                    <Grid item xs={12}>{`Тип: ${
                                        item.Type === "ПО" ? "запись исполнителя" : "звонок оператора"
                                    }`}</Grid>
                                    <Grid item xs={12}>{`Дата создания: ${parseDateTime(
                                        item.CreateDate
                                    )}`}</Grid>
                                </Grid>
                                <Grid container item xs={5}>
                                    <Grid
                                        item
                                        xs={12}
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <PlayArea i={i}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </>
        );
}
