import React, {Component} from 'react';


export default class Page404 extends Component {
    render() {
        return (
            <div className="b_page">
                <h1>Страница не найдена</h1>
            </div>
        );
    }
}