import s from './Header.module.css';
import React from 'react';
import {NavLink} from "react-router-dom";
import { projectName } from '../../helpers/constants';

export default function Header() {

    return (
        <header className={s.b_header}>
            <NavLink to="/" className={s.b_header__logo}>
                <div className={s.b_header__title}>{projectName}</div>
            </NavLink>
            <div className={s.right}>
                <a href="#" className={s.b_logout} onClick={window.authError}>Выйти</a>
            </div>
            <div className="clear"/>
        </header>
    );
}
