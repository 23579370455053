import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Body from './components/Body';
import { BrowserRouter } from 'react-router-dom'
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import {createTheme} from "@mui/material/styles";

const theme = createTheme({
    typography: {
        fontFamily: 'ProximaNova, Arial, sans-serif'
    }
});

ReactDOM.render(
    <BrowserRouter>
        <ThemeProvider theme={theme}>
            <Body />
        </ThemeProvider>
    </BrowserRouter>,
  document.getElementById('root')
);
