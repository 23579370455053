import React from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import ruLocale from 'date-fns/locale/ru';

export default function BasicDatePicker({label, value, onChange, placeholder}) {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
            <DatePicker
                label={label}
                mask={'__.__.____'}
                value={value}
                onChange={(newValue) => {
                    onChange(newValue);
                }}
                renderInput={(params) => <TextField {...{...params, inputProps: {...params.inputProps, placeholder: placeholder}}}  />}
            />
        </LocalizationProvider>
    );
}