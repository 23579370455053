import React, { useCallback, useEffect, useRef, useState } from 'react';
import Box from "@mui/material/Box";
import s from './StaticList.module.css';
import { getRowClassName, rowStyles, sendRequest } from "../../../../helpers/helpers";
import { DataGrid, ruRU } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "../../../../ui/CustomNoRowsOverlay";
import AddressCell from "../../../../ui/AddressCell";
import ContractorCell from "../../../../ui/ContractorCell";
import DefaultCell from "../../../../ui/DefaultCell";
import debounce from "lodash/debounce";

const tableDiffHeight = 250;

export default function StaticList({ filter, selectCard, setFilter }) {
    const classes = rowStyles();

    const [data, setData] = useState([]);

    const [pageNum, setPageNum] = useState(0);
    const [loading, setLoading] = useState(false);
    const [pageSize, setPageSize] = useState(25);
    const [rowCount, setRowCount] = useState(25);
    const mounted = useRef();

    let columns = [
        {
            headerName: 'Дата приема заявки',
            field: 'date_start',
            flex: 1,
            headerClassName: s.th,
            align: 'center',
            headerAlign: "center",
            sortable: false,
            renderCell: function renderDefaultCell(cell) {
                return <DefaultCell cell={cell} selectCard={selectCard} />
            },
        },
        {
            headerName: 'Адрес МКД',
            field: 'address',
            flex: 2,
            headerClassName: s.th,
            headerAlign: "center",
            sortable: false,
            renderCell: function renderAddress(cell) {
                return <AddressCell cell={cell} setFilter={setFilter} filter={filter} />
            }
        },
        {
            headerName: 'Номер помещения',
            field: 'room_num',
            flex: 0.5,
            headerClassName: s.th,
            align: 'center',
            headerAlign: "center",
            sortable: false,
            renderCell: function renderDefaultCell(cell) {
                return <DefaultCell cell={cell} selectCard={selectCard} />
            },
        },
        {
            headerName: 'Тема обращения',
            field: 'theme',
            flex: 2,
            headerClassName: s.th,
            headerAlign: "center",
            sortable: false,
            renderCell: function renderDefaultCell(cell) {
                return <DefaultCell cell={cell} selectCard={selectCard} />
            },
        },
        {
            headerName: 'Статус',
            field: 'status',
            headerClassName: s.th,
            align: "center",
            flex: 0.5,
            headerAlign: "center",
            sortable: false,
            renderCell: function renderDefaultCell(cell) {
                return <DefaultCell cell={cell} selectCard={selectCard} />
            },
        },
        {
            headerName: 'Исполнитель',
            field: 'contractor',
            headerClassName: s.th,
            align: "center",
            flex: 2,
            headerAlign: "center",
            sortable: false,
            // eslint-disable-next-line react/display-name
            renderCell: (cell) => {
                return <ContractorCell cell={cell} setFilter={setFilter} filter={filter} />
            }
        },
        // {
        //     headerName: 'Файл',
        //     field: 'attach',
        //     headerClassName: s.th,
        //     align: "center",
        //     flex: 0.2,
        //     headerAlign: "center",
        //     sortable: false,
        //     renderCell: (cell) => {
        //         return cell.row.attach ? <AttachFileIcon /> : null
        //     }
        // }
    ];

    useEffect(
        () => {
            updateList(filter);
        },
        [
            pageNum,
            pageSize,
            filter.from,
            filter.to,
            filter.show_finished,
            filter.status,
            filter.theme,
            filter.show_time
        ]
    );

    function updateList(filter) {
        setData([]);
        setLoading(true);
        async function getList() {
            const requestData = {
                pageNum: pageNum,
                pageSize: pageSize,
                needTotal: true,
                ...filter
            };

            Object.keys(requestData).forEach((i) => {
                if (requestData[i] === null) {
                    requestData[i] = '';
                }
            });

            const response = await sendRequest('getList', requestData);
            if (response.data && response.data.result && response.data.result.status === 'success') {
                return response.data.result ?? [];
            }
            return [];
        }

        getList().then(result => {
            if (result.data) {
                setData(result.data);
            }
            if (result.pagination) {
                setRowCount(result.pagination.total);
            }

            setLoading(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedUpdateList = useCallback(debounce(updateList, 3000), []);

    useEffect(() => {
        //emulating componentDidUpdate
        if (!mounted.current) {
            mounted.current = true;
        } else {
            debouncedUpdateList(filter);
        }
    }, [filter.address, filter.contractor]);

    const [tableHeight, setTableHeight] = useState(window.innerHeight - tableDiffHeight);

    const handleResize = () => {
        setTableHeight(window.innerHeight - tableDiffHeight);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => { window.removeEventListener('resize', handleResize) }
    }, []);

    return (
        <Box sx={{ height: tableHeight }} className={classes.root}>
            <DataGrid
                rows={data}
                columns={columns}
                disableSelectionOnClick
                disableColumnMenu
                getRowClassName={getRowClassName}
                localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                paginationMode="server"
                pageSize={pageSize}
                rowsPerPageOptions={[25, 50, 100]}
                page={pageNum}
                onPageSizeChange={size => setPageSize(size)}
                onPageChange={(page) => setPageNum(page)}
                rowCount={rowCount}
                sortingMode="server"
                loading={loading}
                components={{
                    NoRowsOverlay: CustomNoRowsOverlay,
                }}
            />
        </Box>
    );
}
