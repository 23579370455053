import Cookies from "universal-cookie";
import axios from "./axios";
import qs from "qs";
import makeStyles from "@mui/styles/makeStyles";
import { darken, lighten } from "@mui/system";
import createTheme from "@mui/material/styles/createTheme";
import s from "../components/Main/Request/DynamicList/DynamicList.module.css";

export async function sendRequest(action, data = {}) {
    data = JSON.stringify(data);
    const body = {
        action: action,
        params: data,
        id: getUserId(),
    };

    if (body.id) {
        let config = {
            headers: {
                "X-Xsrf-Token": getApiTokenFromCookie(),
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            },
        };

        return await axios.post("", qs.stringify(body), config);
    }
    // if (response.data.error.code === "2") {
    //     authError();
    // }

    return false;
}

export async function authUser(credentials) {
    const data = JSON.stringify(credentials);

    const body = {
        action: "Auth",
        params: data,
    };

    let config = {
        headers: {
            "X-Xsrf-Token": "",
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "*/*",
        },
    };

    return await axios.post("", qs.stringify(body), config);
}

export function getApiTokenFromCookie() {
    const cookies = new Cookies();
    return cookies.get("XSRF-TOKEN") ?? "";
}

export function setApiTokenToCookie(token = "") {
    const cookies = new Cookies();
    cookies.set("XSRF-TOKEN", token, { path: "/" });

    axios.defaults.headers.common = {
        ...axios.defaults.headers.common,
        "X-Xsrf-Token": token,
    };
}

export function getUserId() {
    const cookies = new Cookies();
    return cookies.get("userId") ?? "";
}

export function setCookie(name, value, options = {}) {
    const cookies = new Cookies();
    cookies.set(name, value, options);
}

export function getCookie(name) {
    const cookies = new Cookies();
    return cookies.get(name);
}

export function empty(variable) {
    if (typeof variable === "object") {
        if (Object.keys(variable).length === 0) {
            return true;
        }
    }

    return (
        variable === false ||
        variable === 0 ||
        variable === null ||
        variable === undefined
    );
}

export function formatDateForResponse(date = new Date()) {
    return (
        date.getFullYear().toString() +
        ("0" + (date.getMonth() + 1)).slice(-2).toString() +
        ("0" + date.getDate()).slice(-2).toString() +
        "000000"
    );
}

export function formatDate(date = new Date()) {
    return (
        ("0" + date.getDate()).slice(-2) +
        "." +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "." +
        date.getFullYear() +
        " " +
        ("0" + date.getHours()).slice(-2) +
        ":" +
        ("0" + date.getMinutes()).slice(-2) +
        ":" +
        ("0" + date.getSeconds()).slice(-2)
    );
}

export function formatShortDate(date = new Date(), delimiter = ".") {
    return (
        date.getFullYear() +
        delimiter +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        delimiter +
        ("0" + date.getDate()).slice(-2)
    );
}

export function removeDuplicates(arr) {
    const result = [];
    const duplicatesIndices = [];

    // Перебираем каждый элемент в исходном массиве
    arr.forEach((current, index) => {
        if (duplicatesIndices.includes(index)) return;

        result.push(current);

        // Сравниваем каждый элемент в массиве после текущего
        for (
            let comparisonIndex = index + 1;
            comparisonIndex < arr.length;
            comparisonIndex++
        ) {
            const comparison = arr[comparisonIndex];
            const currentKeys = Object.keys(current);
            const comparisonKeys = Object.keys(comparison);

            // Проверяем длину массивов
            if (currentKeys.length !== comparisonKeys.length) continue;

            // Проверяем значение ключей
            const currentKeysString = currentKeys.sort().join("").toLowerCase();
            const comparisonKeysString = comparisonKeys.sort().join("").toLowerCase();
            if (currentKeysString !== comparisonKeysString) continue;

            // Проверяем индексы ключей
            let valuesEqual = true;
            for (let i = 0; i < currentKeys.length; i++) {
                const key = currentKeys[i];
                if (current[key] !== comparison[key]) {
                    valuesEqual = false;
                    break;
                }
            }
            if (valuesEqual) duplicatesIndices.push(comparisonIndex);
        } // Конец цикла
    });
    return result;
}

const defaultTheme = createTheme();
export const rowStyles = makeStyles(
    (theme) => {
        const getBackgroundColor = (color) =>
            theme.palette.mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color) =>
            theme.palette.mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

        return {
            "@global": {
                "@keyframes myEffect": {
                    "0%": {
                        opacity: 0,
                        transform: "scale3d(0, 0, 1)",
                    },
                    "100%": {
                        opacity: 1,
                        transform: "transform: scale3d(1, 1, 1)",
                        backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    },
                },
            },
            root: {
                "& .super-app-theme--tr": {
                    fontSize: "1rem",
                },
                "& .super-app-theme--Open": {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    transition: "background 1s ease",
                    "&:hover": {
                        backgroundColor: getHoverBackgroundColor(
                            theme.palette.warning.main
                        ),
                        transition: "background 1s ease",
                    },
                },
                "& .super-app-theme--Filled": {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    "&:hover": {
                        backgroundColor: getHoverBackgroundColor(
                            theme.palette.success.main
                        ),
                    },
                },
                "& .super-app-theme--Rejected": {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    "&:hover": {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
            },
        };
    },
    { defaultTheme }
);

export const getRowClassName = (params) => {
    let className = "super-app-theme--tr super-app-theme--";
    if (params.row.is_new) className += "Open ";
    else if (params.row.is_deleted) className += "Rejected";
    else if (params.row.is_finished) className += "Filled";
    else className += " " + s.defaultRow;

    //Если к элементу еще не применялась анимация - прописываем класс
    if (params.row.is_new && !params.row.animated) {
        className += ' ' + s.open_animation
    }

    return className;
};
