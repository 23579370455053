import axios from "axios";

// import { Message } from 'element-react';
import { getApiTokenFromCookie } from "./helpers";

axios.defaults.headers.common = {
	'X-Xsrf-Token': getApiTokenFromCookie(),
	'Content-Type': 'application/x-www-form-urlencoded',
	'Accept': '*/*'
};

let baseURL = '';

// eslint-disable-next-line no-undef
if (process.env.REACT_APP_ENV === 'production') {
	baseURL = "https://api.regenergy.ru/api2/uk_requests_boss/";
} else {
	baseURL = "https://api.regenergy.ru/api2_test/uk_requests_boss/";
}

const http = axios.create({
	baseURL,
	responseType: "json",
});

http.interceptors.response.use(response => {
	if (response.data.error.msg === 'expired') {
		window.authError();
	}
	return response;
}, error => {
	if (error.response.status === 401) {
		window.authError();
	} else {
		// Message({
		// 		showClose: true,
		// 		message: "AJAX неверный код ответа: #"+error.response.status,
		// 		type: 'error',
		// 	});
	}
	return error.response;
});

window.axios = http;

export default http;


