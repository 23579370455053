import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

export default function MoreDataGridFooter({loading, onClick, show}) {
    return <Box m={2} sx={{textAlign: 'center', position: 'relative'}}>
        {show && <Button variant="outlined"
                sx={loading
                    ? {background: '#e6e6e6', color: '#e6e6e6'}
                    : null}
                onClick={loading ? null : onClick}>
            Показать ещё
        </Button>}
        {loading && (
            <CircularProgress
                size={24}
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                }}
            />
        )}
    </Box>
}