import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";

export default function ContractorCell({ cell, setFilter, filter }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const setContractorFilter = () => {
        setFilter({ ...filter, contractor: cell.row.contractor });
        handleClose();
    };
    const setContractorAddressFilter = () => {
        setFilter({ ...filter, contractor: cell.row.contractor, address: cell.row.address + ', ' + cell.row.room_num });
        handleClose();
    };

    return (
        <Box sx={{ width: '100%', height: '100%', }}>
            <Box sx={{
                width: '100%',
                height: '100%',
                lineHeight: '51px',
                color: '#0235a0',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center'
            }}
                onClick={handleClick}
            >
                <Box sx={{
                    lineHeight: '16px',
                    borderBottom: '1px solid #0235a0',
                    paddingBottom: '3px',
                }}>
                    {cell.formattedValue}
                </Box>
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={setContractorFilter}>Показать все заявки по этому исполнителю</MenuItem>
                <MenuItem onClick={setContractorAddressFilter}>Показать все заявки по этому исполнителю по данному адресу</MenuItem>
            </Menu>
        </Box>
    );
}