import React, {useState} from 'react';
import StaticList from "./StaticList/StaticList";
import DynamicList from "./DynamicList/DynamicList";
import Filter from "./Filter";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "./RequestCard";

export default function List() {
    const [mode, setMode] = useState('dynamic');

    const toggleMode = () => {
        setSelectedCard(false);
        if (mode === 'static') {
            setMode('dynamic');
        } else {
            setMode('static');
        }
    };

    const [filter, setFilter] = useState({
        from: null,
        to: null,
        show_finished: true,
        status: '',
        address: '',
        theme: '',
        show_time: true,
        contractor: '',
    });

    const [selectedCard, setSelectedCard] = useState(false);


    return <Box>
        <Filter mode={mode}
                toggleMode={toggleMode}
                filter={filter}
                setFilter={setFilter}
        />
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={!selectedCard ? 12 : 6}>
                    {mode === 'static'
                        ? <StaticList
                            filter={filter}
                            selectCard={setSelectedCard}
                            setFilter={setFilter}
                        />
                        : <DynamicList
                            filter={filter}
                            selectCard={setSelectedCard}
                            setFilter={setFilter}
                        />}
                </Grid>
                {!!selectedCard
                && <Grid item xs={6}>
                    <Card
                        id={selectedCard}
                        close={() => setSelectedCard(false)}
                    />
                </Grid>}
            </Grid>
        </Box>
    </Box>;
}
