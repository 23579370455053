import React, { useState, useEffect } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import ClearIcon from '@mui/icons-material/Clear';
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import TextField from "@mui/material/TextField";
import DatePicker from "../../../ui/DatePicker";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { sendRequest } from "../../../helpers/helpers";
import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";
import { formatDateTime } from "../../../helpers/convert"


export default function Filter({ mode, toggleMode, filter, setFilter }) {
    const [open, setOpen] = useState(false);

    const toggleShowFilter = () => {
        setOpen(!open);
    };

    // пока решил внаглую вбить возможные значения фильтра, а не лезть в бд через кондуктор
    const statuses = [
        'Принята',
        'В работе',
        'Выполнена',
        'Отменена',
    ];
    const [themes, setThemes] = useState([]);

    //constructor
    useEffect(() => {
        async function getList() {
            const response = await sendRequest('getThemes');
            if (response.data && response.data.result && response.data.result.status === 'success') {
                return response.data.result ?? [];
            }
            return [];
        }

        getList().then(result => {
            if (result.data) {
                setThemes(result.data);
            }
        });
    }, []);

    //destructor
    useEffect(() => () => {
        setFilter({
            from: null,
            to: null,
            show_finished: true,
            status: '',
            address: '',
            theme: '',
            show_time: true,
            contractor: '',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Box mb={2}>
        <Grid container
            spacing={2}
            alignItems='center'
        >
            <Grid item xs={12}>
                <Grid container spacing={2} alignItems='center'>
                    <Grid item>
                        <Tooltip
                            title={'Динамический режим подразумевает работу с заявками онлайн. Только что пришедшие заявки будут появляться выше остальных и выделяться цветом.'}>
                            <ToggleButtonGroup
                                color="primary"
                                exclusive
                                value={mode}
                                onChange={toggleMode}
                            >
                                <ToggleButton value="dynamic" color='primary'>Динамический</ToggleButton>
                                <ToggleButton value="static" color='secondary'>Статический</ToggleButton>
                            </ToggleButtonGroup>
                        </Tooltip>
                    </Grid>

                    <Grid item>
                        <Button onClick={toggleShowFilter}>
                            <FilterListIcon color={open ? 'primary' : 'action'} />
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Collapse in={open}>
            <Grid container
                mt={1}
                spacing={2}
                alignItems='center'
            >
                <Grid item container spacing={2}>
                    <Grid item container spacing={2} alignItems='center'>
                        <Grid item xs={12}>
                            <Box sx={{ fontWeight: 'bold' }}>Период подачи заявок</Box>
                        </Grid>
                        <Grid item>
                            <DatePicker
                                label='от'
                                value={filter.from}
                                onChange={value => setFilter({ ...filter, from: formatDateTime(value, true) })}
                                placeholder='от'
                            />
                        </Grid>
                        <Grid item>
                            <DatePicker
                                label='до'
                                value={filter.to}
                                onChange={value => setFilter({ ...filter, to: formatDateTime(value) })}
                                placeholder='до'
                            />
                        </Grid>
                        <Grid item>
                            <Button onClick={() => setFilter({ ...filter, from: null, to: null })}
                                variant={'outlined'}
                                disabled={filter.from === null && filter.to === null}
                            >
                                За все даты
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <FormControlLabel control={
                            <Checkbox
                                checked={filter.show_finished}
                                onChange={() => setFilter({ ...filter, show_finished: !filter.show_finished })}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />}
                            label="Показывать выполненные" />
                    </Grid>
                </Grid>
                <Grid item container spacing={2}>
                    <Grid item container spacing={2} alignItems='center'>
                        <Grid item lg={4} md={6} sm={12}>
                            <TextField
                                id="standard-number"
                                label="Адрес МКД"
                                value={filter.address}
                                onChange={e => setFilter({ ...filter, address: e.target.value })}
                                type="text"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <IconButton onClick={() => setFilter({ ...filter, address: '' })}>
                                            <ClearIcon color="disabled" fontSize="small" />
                                        </IconButton>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item lg={3} md={6} sm={12}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="status-select-label">Статус</InputLabel>
                                <Select
                                    value={filter.status}
                                    onChange={e => setFilter({ ...filter, status: e.target.value })}
                                    input={<OutlinedInput label="Статус" fullWidth />}
                                >
                                    <MenuItem value={''}>Все статусы</MenuItem>
                                    {statuses.map(status => <MenuItem key={'status_' + status} value={status}>{status}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item lg={2} md={6} sm={12}>
                            <FormControl sx={{ width: '100%' }}>
                                <Autocomplete
                                    fullWidth
                                    disablePortal
                                    options={themes}
                                    // sx={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="Тема обращения" />}
                                    value={filter.theme}
                                    onChange={(e, newValue) => setFilter({ ...filter, theme: newValue })}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={6} sm={12}>
                            <TextField
                                label="Исполнитель"
                                value={filter.contractor}
                                onChange={e => setFilter({ ...filter, contractor: e.target.value })}
                                type="text"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <IconButton onClick={() => setFilter({ ...filter, contractor: '' })}>
                                            <ClearIcon color="disabled" fontSize="small" />
                                        </IconButton>
                                    )
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <FormControlLabel control={
                            <Checkbox
                                checked={filter.show_time}
                                onChange={() => setFilter({ ...filter, show_time: !filter.show_time })}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />}
                            label="Показывать время" />
                    </Grid>
                </Grid>
            </Grid>
        </Collapse>
    </Box>
}