
export function formatDate(date) {
    if (typeof date != "object") {
        return "";
    }
    var d = new Date(date);
    var month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    if (year <= 1970) {
        return "";
    }
    return [year, month, day].join('-');
}

export function formatDateTime(date, from = false) {
    if (typeof date != "object") {
        return "";
    }
    var d = new Date(date);
    var month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    if (year <= 1970) {
        return "";
    }

    // var h = '' + d.getHours(),
    //     i = '' + d.getMinutes(),
    //     s = d.getSeconds();

    var h = '',
        i = '',
        s = ''

    if (from) {
        h = '00',
            i = '00',
            s = '00'
    } else {
        h = '23',
            i = '59',
            s = '59'
    }

    // if (h.length < 2)
    //     h = '0' + h;
    // if (i.length < 2)
    //     i = '0' + i;

    // if (s.length < 2)
    //     s = '0' + s;

    return [year, month, day].join('-') + "T" + [h, i, s].join(':');
}

export function parseDateTime(date) {
    var d = new Date(date);
    var month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    if (year <= 1970) {
        return "";
    }

    var h = '' + d.getHours(),
        i = '' + d.getMinutes(),
        s = '' + d.getSeconds();

    if (h.length < 2)
        h = '0' + h;
    if (i.length < 2)
        i = '0' + i;
    if (s.length < 2)
        s = '0' + s;

    return `${day}.${month}.${year} ${h}:${i}:${s}`;
}