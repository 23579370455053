import React, {useState} from 'react';
import s from './Auth.module.css';
// import {Form, Button, Input, Message} from 'element-react';
import {authUser, setApiTokenToCookie, setCookie} from "../../helpers/helpers";
import {projectName} from "../../helpers/constants";
import {FormControl, Button} from '@mui/material';
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export default function Auth(props) {
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [status, setStatus] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    setTimeout(() => {
        setStatus(s.active);
    }, 500);

    function showError(message) {
        console.log(message);
        // Message({
        //     showClose: true,
        //     message: message,
        //     type: 'error',
        // });
    }

    async function onSubmit(e) {
        e.preventDefault();
        setIsLoading(true);
        const form = {
            login: login,
            password: password
        };
        let userState = await authUser(form);

        setIsLoading(true);

        if (userState.data && userState.data.result && userState.data.result.status === 'success') {
            setApiTokenToCookie(userState.headers['x-xsrf-token']);
            setCookie('userId', userState.data.result.id, {path: '/'});
            props.func(true);
        } else {
            if (userState && userState.data && userState.data.error && typeof userState.data.error.message != "undefined")
                showError(userState.data.error.message);
            else
                showError('Ошибка авторизации пользователя.');
        }

        setIsLoading(false);
    }

    function inputKeyPress(e) {
        if (e.key === 'Enter') {
            onSubmit(e);
        }
    }

    let className = s.b_auth + " board " + status;

    return (
        <main className={className}>
            <FormControl>
                <h2>{projectName}</h2>
                <TextField className={s.elInput}
                           placeholder="Ваш логин"
                           value={login}
                           onChange={(e) => setLogin(e.target.value)}
                           onKeyPress={inputKeyPress}/>
                <TextField className={s.elInput}
                           placeholder="Пароль"
                           type='password'
                           value={password}
                           onChange={(e) => setPassword(e.target.value)}
                           onKeyPress={inputKeyPress}/>
                <Box className={s.login}>
                    {isLoading
                        ? <CircularProgress/>
                        : <Button variant='contained'
                                  className={s.login}
                                  onClick={onSubmit}>
                            Войти
                        </Button>}
                </Box>
            </FormControl>
        </main>
    );
}


